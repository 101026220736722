import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/Kinetik/Navbar";
import Footer from "../components/Kinetik/Footer";
import PageBanner from '../components/Common/PageBanner';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

import BlogImg1 from "../assets/images/blog-image/blog1.jpg";
import BlogImg2 from "../assets/images/blog-image/blog2.jpg";
import BlogImg3 from "../assets/images/blog-image/blog3.jpg";
import BlogImg4 from "../assets/images/blog-image/blog4.jpg";
import BlogImg5 from "../assets/images/blog-image/blog5.jpg";
import BlogImg6 from "../assets/images/blog-image/blog6.jpg";

const Blog1 = () => (
    <Layout>
        <SEO title="Blog" /> 

        <Navbar />

        <PageBanner pageTitle="Impressum" />

        <div className="blog-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    {/*<div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg1} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 15, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        The Security Risks of Changing Package Owners
                                    </Link>
                                </h3>

                                <span>By <Link to="/author">Admin</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg2} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 17, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        Tips to Protecting Your Business and Family
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">Smith</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg3} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 19, 2021
                                </div>
                            </div>

                            <div className="blog-post-content"> 
                                <h3>
                                    <Link to="/blog-details">
                                        Protect Your Workplace from Cyber Attacks
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">John</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg4} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 15, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        Here are the 5 most telling signs of micromanagement
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">Admin</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg5} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 17, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        I Used The Web For A Day On A 50 MB Budget
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">Smith</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">*/}
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    {/*<img src={BlogImg6} alt="image" />*/}
                                </Link>

                                {/*<div className="date">
                                    <Icon.Calendar /> March 19, 2021
                                </div>*/}
                            </div>

                            <div className="blog-post-content">
                                <b>Adresse:</b>
                                <p>
                                KiNETiK Gesellschaft für Informationstechnik mbH<br></br>
                                Raiffeisenstr. 3<br></br>
                                95463 Bindlach<br></br>
				Deutschland</p>

                                <b>Geschäftsführer</b>
                                <p> Dipl. Inform. Andreas Kurzac </p>
                                
                                <b>Ust. ID:</b>
                                <p>DE 812207171</p>
                                
                                <b>Handelregistereintrag:</b>
                                <p>Handelsregister Bayreuth<br></br>
                                HRB 2703<br></br>
                                Sitz Bayreuth<br></br>
				</p>				
				<b>Kontakt:</b>
				<p>eMail: info@kinetik.de<br></br>
				Tel.: +49 (0) 921 – 730050 – 0<br></br>
				Fax: +49 (0) 921 – 730050 – 55<br></br>
                                </p>
                                
                                {/*<Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>*/}
                            </div>
                            <div className="blog-post-content">
                               <b>Verantwortlich gemäß § 5 Abs. 1 TMG:</b>
                               <p>Dipl. Inform. Andreas Kurzac
                               </p>
                               <b>Erfüllungsort und Gerichtsstand:</b>
                               <p>Bayreuth
                               </p>
                               <b>Quellenangaben für die verwendeten Bilder:</b>
                               <p>http://www.dreamstime.com
                               </p>
                               <b>Haftungshinweis für Inhalte</b>
                               <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                               </p>
                               <b>Haftungshinweis für Links</b>
                               <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                               </p>
                               <b>Urheberrecht</b>
                               <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                               </p>


                            </div>
                        </div>
                    {/*</div>*/}
                    
                    {/* Pagination */}
                    <div className="col-lg-12 col-md-12">
                        <div className="pagination-area">
                            <nav aria-label="Page navigation">
                                <ul className="pagination justify-content-center">
                                    {/*<li className="page-item">
                                        <Link to="#" className="page-link">
                                            Prev
                                        </Link>
                                    </li>

                                    <li className="page-item active">
                                        <Link to="#" className="page-link">1</Link>
                                    </li>

                                    <li className="page-item">
                                        <Link to="#" className="page-link">2</Link>
                                    </li>

                                    <li className="page-item">
                                        <Link to="#" className="page-link">3</Link>
                                    </li> 

                                    <li className="page-item">
                                        <Link to="#" className="page-link">
                                            Next
                                        </Link>
                                    </li>*/}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Blog1;